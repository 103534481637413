/* This SCSS file is responsible for all of the CSS on list pages including table formatting.
 */
@import "globals";
@import "brand_colors";
@import "risk_colors";

/* This is to override the datatables styling the makes it centered. */
@media screen and (max-width: 767px) {
  .container div.dataTables_wrapper div.dataTables_length {
    text-align: left;
  }

  .container div.dataTables_wrapper div.dataTables_info,
  .container div.dataTables_wrapper div.dataTables_paginate {
    text-align: right;
  }
}

.group-by-table-container {
  padding-bottom: 20px;

  thead .sorting::before,
  thead .sorting_asc::before,
  thead .sorting_desc::before,
  thead .sorting::after,
  thead .sorting_desc::after,
  thead .sorting_asc::after {
    top: 8px;
  }

  .group-row {
    background: $brand-light-grey;
    border: 1px solid $brand-mild-grey;
  }

  .chevron-right-icon,
  .chevron-down-icon {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
  }

  .chevron-right-icon.hidden,
  .chevron-down-icon.hidden,
  tr.hidden,
  td.hidden {
    display: none;
  }

  th, td {
    vertical-align: top !important;
    white-space: nowrap;
  }

  .dataTables_wrapper {
    > div {
      overflow: auto;
    }

    thead > tr:first-child > th,
    tbody > tr:first-child > td {
      border-top: none;
    }

    thead > tr:last-child > th {
      border-bottom: 2px solid #dee2e6;
    }
  }

  /* The background color shouldn't be shown on the list pages. If you remove the `.not(.list-tables)` check the batches
   * lists and the ITP page to make sure they're both not broken.
   */
  /* stylelint-disable no-descending-specificity */
  &:not(.list-tables) .dataTables_wrapper table.dataTable {
    border: 1px solid #c3c3c2 !important;
    margin-top: 0 !important;

    > thead > tr {
      background-color: $brand-light-grey;
      color: $brand-medium-grey;
    }
  }

  .dataTables-nav {
    margin-left: 0;
    margin-right: 0;
  }

  /* stylelint-enable no-descending-specificity */
}

table.dataTable td.dataTables_empty {
  text-align: left !important;
  color: $brand-medium-grey !important;
}

td.dt-body-right {
  text-align: right;
}

#resultsTable, .results-table {
  margin-top: 20px;
}

.results-table-column-id {
  width: 80px !important;
}

.results-table-column-user {
  min-width: 100px;
}

.results-table-column-date {
  width: 190px;
}

.results-table-row-archived {
  background-color: $brand-mild-grey;
  font-style: italic;
}

.results-table-approved-flag {
  color: $brand-primary-color;
  font-style: italic;
  padding: 2px;
  text-decoration: underline;
  text-decoration-color: $brand-light-grey; // Sets the link underline to the table background color, since it can't be unset for portion of the link.
}

.results-table-demo-flag {
  color: $brand-medium-grey;
  font-style: italic;
}

@media print {
  .dataTable th, .dataTable label span {
    color: $brand-dark-grey !important;
  }
}

.dataTables-nav {
  align-items: center;

  label {
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding-top: 0 !important;
    text-align: right;
  }

  .dataTables_paginate {
    float: right;
  }

  ul.pagination {
    flex-flow: wrap;
  }
}

.dataTables-show-removed label {
  font-weight: normal;
}

.tableActionImage {
  margin-right: 10px;
}

.table-manage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 70px;
  text-align: right;
  margin-right: 10px;
}

.detailed-data-section-table .table-manage {
  float: right;
}

.table-manage div {
  width: auto;
}

.table-manage a {
  margin-left: 2px;
}

.fa-lg {
  font-size: 1.6em;
}

// Generally for the add button
.button-float-right {
  float: right;
  padding-top: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
}

#layoutsListTable tbody tr.selected,
#dependencySelectionTable tbody tr.selected {
  background-color: $brand-mild-grey;
}

div.list-table-risk-label {
  margin-top: 4px !important;
  width: 12px;
  height: 12px;
  border-style: none;
  margin-right: 8px;
  float: left;
  position: relative;
}

.list-table-risk-label-label {
  overflow-wrap: initial;
}

.list-table-risk-label-process-explorer {
  margin-top: 2px !important;
}

.list-table-filter-bar {
  margin: 27px 0;
  padding: 0;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;

    li {
      display: inline-block;

      a {
        border-radius: 4px;
        border: solid 1px transparent;
        display: inline-block;
        margin: 0 20px 0 0;
        padding: 13px 12px;
        text-decoration: none;

        &.selected, &:hover {
          border: solid 1px $brand-mild-grey;
        }

        &.selected {
          background-color: $brand-white;
        }

        .filter-count {
          font-size: calculateEm(36px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $brand-black;
          margin-right: 8px;
        }

        .filter-name {
          font-size: calculateEm(14px);
          font-family: "Noto Sans", "Roboto", sans-serif;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $brand-black;
        }
      }
    }
  }
}

.search-box {
  input[type=text] {
    height: 50px;
    padding-left: 42px;
    background: transparent;
  }

  .search-icon {
    position: relative;
    top: 35px;
    left: 20px;
    color: $brand-medium-grey;
  }
}

.search-item {
  padding: 10px 0;
  margin: 0;

  .search-item-icon {
    color: $brand-medium-grey;
    float: left;
  }

  .search-item-info {
    padding-left: 26px;

    .search-item-author {
      color: $brand-medium-grey;
    }
  }
}

.table-with-checkboxes-search-box {
  margin-bottom: 35px;
}

/* There is no way to fix this without breaking it up and then we break the rule of having duplicate CSS classes. */
/* stylelint-disable no-descending-specificity */
.table-bordered {
  border-right: 0;
  border-left: 0;

  a {
    font-weight: bold;
  }

  .dropdown-menu a {
    font-weight: 400;
  }

  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > th,
  & > tbody > tr > td,
  & > tfoot > tr > th,
  & > tfoot > tr > td {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 0;
    border-left: 0;
  }

  & > thead > tr > th,
  & > tbody > tr > th,
  & > tfoot > tr > th {
    border-bottom: 0;
  }

  & > thead > tr > th,
  & > tbody > tr > th {
    font-weight: normal;
    font-size: calculateEm(12px);
    color: $brand-medium-grey;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & > thead > tr > td,
  & > tbody > tr > td,
  & > tfoot > tr > td {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

/* stylelint-enable no-descending-specificity */

.list-table-not-borders th:first-child,
.list-table-not-borders td:first-child {
  padding-left: 65px;
}

/* There is no way to fix this legacy code without rewriting/breaking up this CSS almost entirely. */
/* stylelint-disable no-descending-specificity */
.results-table td, .detailed-data-section-table td, #resultsTable td {
  vertical-align: middle !important;
}

/* stylelint-enable no-descending-specificity */

#riskTables,
#RMPRiskTableContainer {
  .table-bordered {
    border: 1px solid $brand-mild-grey;
    border-right: 0;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid $brand-mild-grey;
  }

  .table > thead > tr > th,
  .table > thead > tr > td {
    border-bottom-width: 2px !important;
  }

  .table-bordered > thead > tr > th,
  .table-bordered > tbody > tr > th {
    font-weight: bold;
    font-size: calculateEm(14px);
    color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 0;
  }

  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 0;
  }

  .table-bordered.dataTable th,
  table.table-bordered.dataTable td {
    border-left-width: 0;
    border-bottom-width: 0;
  }
}

#baseLinksAttributesTable .table-bordered {
  border: 1px solid $brand-mild-grey;
}

#riskActionConfirmationTable_wrapper .table-bordered {
  border: 1px solid #ccc;
}

#dependencySelectionTable_wrapper,
#importResultsSummaryTable_wrapper,
#importDataReviewTable_wrapper,
#approvalPopupTable_wrapper,
#documentBulkAddTable_wrapper,
#documentBulkAddResultsTable_wrapper,
#usersTable_wrapper {
  .table > caption + thead > tr:first-child > th,
  .table > caption + thead > tr:first-child > td,
  .table > colgroup + thead > tr:first-child > th,
  .table > colgroup + thead > tr:first-child > td,
  .table > thead:first-child > tr:first-child > th,
  .table > thead:first-child > tr:first-child > td {
    border-top: 0;
    border-bottom: 1px solid #ccc;
  }
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after,
table.dataTable thead .sorting_asc_disabled::after,
table.dataTable thead .sorting_desc_disabled::after {
  bottom: 5px;
}

.table-icon,
.table-options-button {
  font-size: 1em;
  color: $brand-medium-grey;
}

.table-options-button {
  margin-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  background-color: transparent;
  border: 0;
}

.table-options-button:hover {
  background-color: #ededed;
  color: $brand-medium-grey;
}

.table-row-options-button {
  min-width: 120px;

  span {
    font-family: "Open Sans", sans-serif;
    font-size: calculateEm(14px);
  }

  .table-row-options-button-icon {
    margin-right: 15px;
    color: $hover-color;
  }
}

.table-manage .dropdown-menu {
  left: auto;

  .divider {
    margin: 0;
  }
}

.document-bulk-add-footer {
  padding-right: 25px;
  padding-left: 25px;
}

.document-bulk-add-form,
.document-bulk-add-results-form {
  overflow-y: auto;
  margin-top: 10px;
  max-height: 500px;
}

.document-bulk-add-upload-success {
  color: $brand-highlight-color;
}

.table-read-only {
  tbody, thead {
    tr {
      background-color: $brand-light-grey !important;
    }
  }
}

.list-table-not-borders {
  border: 0 !important;
}

/* There is no way to fix this legacy code without rewriting/breaking up this CSS almost entirely. */
/* stylelint-disable no-descending-specificity */
.risk-tables-cell-blue,
.risk-tables-cell-blue a,
.risk-tables-cell-blue a:hover {
  background-color: $risk-table-cell-very-low-color !important;
  color: $brand-white !important;
}

.risk-tables-cell-green,
.risk-tables-cell-green a,
.risk-tables-cell-green a:hover {
  background-color: $risk-table-cell-low-color !important;
  color: $brand-white !important;
}

/* stylelint-enable no-descending-specificity */

.risk-tables-cell-yellow {
  background-color: $risk-table-cell-medium-color !important;
  color: black !important;
}

.risk-tables-cell-orange {
  background-color: $risk-table-cell-high-color !important;
  color: $brand-white !important;
}

.risk-tables-cell-grey {
  background-color: $risk-table-cell-not-assessed-color !important;
  color: $brand-white !important;
}

/* There is no way to fix this legacy code without rewriting/breaking up this CSS almost entirely. */
/* stylelint-disable no-descending-specificity */
.risk-tables-cell-red,
.risk-tables-cell-red a,
.risk-tables-cell-red a:hover {
  background-color: $risk-table-cell-very-high-color !important;
  color: $brand-white !important;
}

/* stylelint-enable no-descending-specificity */

@media print {
  .table td.risk-tables-cell-blue {
    background-color: $risk-table-cell-very-low-color !important;
    color: $brand-white !important;
  }

  .table td.risk-tables-cell-green {
    background-color: $risk-table-cell-low-color !important;
    color: $brand-white !important;
  }

  .table td.risk-tables-cell-yellow {
    background-color: $risk-table-cell-medium-color !important;
    color: black !important;
  }

  .table td.risk-tables-cell-orange {
    background-color: $risk-table-cell-high-color !important;
    color: $brand-white !important;
  }

  .table td.risk-tables-cell-red {
    background-color: $risk-table-cell-very-high-color !important;
    color: $brand-white !important;
  }

  .table td.risk-tables-cell-grey {
    background-color: $risk-table-cell-not-assessed-color !important;
    color: $brand-white !important;
  }
}

.risk-tables-row-header-cell {
  font-weight: bold;
  margin-right: 15px;
}

.risk-tables-row-header-cell-uo {
  font-weight: bold;
  font-size: calculateRem(10px);
  margin-left: 10px;
}

.risk-tables-footer-header-cell {
  text-align: right !important;
}

.risk-tables-footer-cell {
  font-weight: normal;
}

.risk-tables-footer-cell-divider {
  border-top-style: solid !important;
  border-top-width: 2px !important;
  border-top-color: black !important;
}

.risk-tables-multi-link {
  padding-bottom: 10px;
  display: block;
}

.risk-tables-column-stamp {
  font-family: "old-stamper", serif;
  font-weight: normal;
  font-size: calculateRem(10px);
}

.risk-tables-cell-no-data {
  font-style: normal;
}

.risk-tables-column-version {
  font-weight: bold;
  font-size: calculateRem(10px);
  color: grey;
}

.risk-tables-uo-stamp {
  font-family: "old-stamper", serif;
  font-weight: normal;
  margin-left: 15px;
}

#collapseGeneralAttributeListTable,
#collapseTPPListTable,
#collapseFQAListTable,
#collapseFPAListTable,
#collapseMTSListTable {
  table {
    margin-bottom: -2px;
    margin-top: 0;
  }
}

.sort-table-view {
  color: $brand-medium-grey;

  span {
    color: black;
  }

  span:first-child {
    padding-left: 25px;
  }
}
.dataTables_wrapper table.dataTable,
.dataTables_scroll table.dataTable {
  color: $brand-dark-grey;

  > tbody > tr.odd > *,
  > tbody > tr.even > * {
    background-color: $brand-white;
  }

  > tbody > tr.selected > * {
    box-shadow: none !important;
    color: $brand-dark-grey;
  }

  > tbody > tr:hover > *,
  > tbody > tr.selected:hover > * {
    box-shadow: none !important;
    background-color: $brand-light-grey;
  }
}

.scoreInfo {
  .value {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }
  .label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 2px;
  }

  &.singleLine {
    .value {
      float: left;
      margin-right: 5px;
    }
    .label {
      margin-top: 0px;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }
  }
}