/* This SCSS file is responsible for all of the CSS for the tech transfer.
 */
@import 'brand_colors';
@import 'globals';

$tech-transfer-border: #d9d9d9 solid thin;

#techTransferReviewError {
  margin-left: 15px;
  margin-right: 60px;
}

.column-selection-tooltip-title {
  a {
    font-size: 14px;
  }

  background: white;
  border-bottom: $hover-color solid thin;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;
  padding: 10px 5px 10px 15px;
  position: sticky;
  top: 0;
  z-index: 100;
}

#techTransferTable,
#productTable {
  tr {
    background-color: white;
  }

  a {
    font-weight: bold;
  }

  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > th,
  & > tbody > tr > td,
  & > tfoot > tr > th,
  & > tfoot > tr > td {
    color: #434952;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 0;
    font-size: 14px;
    font-weight: normal;
  }

  & > thead > tr > th,
  & > tbody > tr > th,
  & > tfoot > tr > th {
    border-bottom: 1px solid #ccc;
  }

  & > thead > tr > th {
    font-weight: bold;
  }

  th:first-child,
  td:first-child {
    border-left: 1px solid #ccc;
  }
}

.conlumn-selection-close-icon {
  cursor: pointer;
}

.tech-transfer-select,
.tech-transfer-model-select {
  width: fit-content;
  max-width: 220px;
  min-width: 160px;
}

.tech-transfer-model-select {
  margin-left: 10px;
}

.tech-transfer-bar-comparison {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.configurable-export-button, .configurable-import-button {
  height: 30px;
  margin: 0 0 0 10px;
  padding-top: 5px;
}

.tech-transfer-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  justify-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.tech-transfer-filter-div {
  display: flex;
}

.tech-transfer-title {
  font-size: calculateRem(18px);
  font-weight: 600;
}

.tech-transfer-unit-operation-typeahead {
  margin-right: 10px;
  margin-top: 10px;
  width: 200px;
}

.tech-transfer-icon,
.tech-transfer-compare-icon,
.conlumn-selection-close-icon {
  margin-left: 10px;
  margin-right: 10px;
  color: $brand-medium-grey;
}

.tech-transfer-assessment-icon {
  margin-left: 0;
  margin-right: 5px;
  color: $brand-medium-grey;
}

.tech-transfer-assessment-border {
  border-top: $brand-mild-grey solid thin;
}

.tech-transfer-page-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tech-transfer-container {
  height: 1%;
  margin-left: 5px;
  flex-grow: 1;

  &:has(.skeleton) {
    margin-left: 0;
  }
}

.tech-transfer-inner-container {
  height: 100%;

  .error-bar {
    margin-left: 0;
    margin-right: 0;
  }
}

.tech-transfer-sub-text {
  font-size: calculateEm(12px);
  color: $brand-dark-grey;
}

.tech-transfer-table-container {
  padding-bottom: 20px;
}

.tech-transfer-table-sticky-header {
  background-color: $brand-white;
  top: 35px;
}

.tech-transfer-table-sticky-first-header {
  z-index: 90;
}

.tech-transfer-table-sticky-col {
  background-color: $brand-white;
  left: 0;
  position: sticky;
  border: solid thin;
  border-right-color: rgba(33, 37, 41, 0.5);
}

.configurable-tables-risk-color-column-label {
  color: $brand-medium-grey;
}

.configurable-tables-risk-color-column {
  display: inline-block;
  height: 20px;
  margin-right: 5px;
  width: 20px;
}

.tech-transfer-column-slim {
  width: 50px;
}

.tech-transfer-changed-yellow {
  background-color: #fff9e6 !important;
}

.tech-transfer-title-align {
  text-align-last: center;
  z-index: 100 !important;
  background: white;
}

.tech-transfer-review-header {
  border: $tech-transfer-border;
  font-weight: bold;
  padding-top: 10px;
}

.tech-transfer-attribute-column-sub-header {
  font-weight: normal;
  font-size: calculateEm(12px);
}

.tech-transfer-review-row-container {
  border: $tech-transfer-border;
  padding-bottom: 10px;
  padding-top: 10px;
  word-break: break-all;
}

.tech-transfer-review-section-title-container {
  background: $brand-light-grey;
  border: $tech-transfer-border;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-top: 5px;
}

.tech-transfer-review-screen-container {
  background-color: $brand-light-grey;
}

.tech-transfer-review-screen-header {
  align-items: baseline;
  border-bottom: solid thin $brand-mild-grey;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px;
}

.tech-transfer-review-screen-header-close-icon {
  margin-right: 50px;
}

.tech-transfer-ignore-changes-confirmation-text {
  font-size: calculateEm(12px);
}

.tech-transfer-review-screen-header-actions {
  display: flex;
  align-items: baseline;
}

.tech-transfer-review-screen-ignore-changes-container {
  align-items: center;
  border: $tech-transfer-border;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
}

.tech-transfer-review-icon {
  color: $brand-primary-color;
  cursor: pointer;
}

.tech-transfer-review-screen-body {
  height: 65vh;
  margin: 10px;
  overflow-y: auto;
  padding: 5px;
}

.tech-transfer-diff-container {
  background: white;
  border-top: $tech-transfer-border;
  border-bottom: $tech-transfer-border;
  float: left;
  margin-bottom: -15px;
}

.tech-transfer-editor-container {
  background: $brand-light-grey;
  float: right;
  margin-top: 15px;
}

.tech-transfer-review-screen-editor-title {
  display: inline-block;
}

.tech-transfer-review-screen-editor-label {
  display: block;
}

.tech-transfer-diff-column-tooltip-title {
  border-bottom: $hover-color solid thin;
  font-weight: 600;
  font-size: 14px;
  padding: 5px;
  width: 250px;
}

.configurable-tables-column-selection-body-container {
  padding: 5px 5px 5px 15px;
  margin-bottom: 100px;

  > a {
    font-weight: bold;
  }
}

.tech-transfer-diff-column-tooltip-body-section {
  padding-bottom: 10px;
}

.tech-transfer-bar-container {
  background-color: $brand-light-grey;
  border-bottom: solid thin #dadada;
  padding-bottom: 10px;

  @media (max-width: 767px) {
    .container {
      width: 100%;
    }
  }
}

.tech-transfer-process-components-children {
  padding-left: 38px !important;
}

.tech-transfer-process-components-children-one-line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.tech-transfer-process-components-multi-line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.tech-transfer-parent-attribute-cell {
  display: inline-flex;
}

.tech-transfer-process-component-diff-summary {
  border: solid thin $brand-mild-grey;
  background-color: $brand-light-grey;
  color: black;
  font-size: calculateEm(12px);
  margin-top: 5px;
  padding: 5px;
}

.tech-transfer-variable-review-div {
  display: flex;
  justify-content: space-between;
}

.tech-transfer-acceptance-criteria-column-group {
  background: $brand-light-grey;
  border-radius: 5px;
  padding: 4px;
}

.tech-transfer-missing-record {
  color: $brand-medium-grey;
  font-size: calculateEm(12px);
  font-weight: 600;
}

.tech-transfer-missing-record-flex {
  display: inline-flex;
  align-items: center;
}

.tech-transfer-disabled-review-icon {
  color: $brand-mild-grey;
}

.tech-transfer-secondary-button {
  border-color: $brand-mild-grey;
}

.tech-transfer-secondary-icon {
  color: $brand-medium-grey;
}

.column-selection-footer-container {
  display: flex;
  justify-content: flex-end;
  background: $brand-light-grey;
  border-bottom: solid thin $brand-mild-grey;
  border-radius: 5px 5px 0 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  bottom: 0;
}

.column-selection-popup {
  max-height: 85vh !important;
  max-width: 100vw;
}

.column-selection-entry {
  align-items: center;
  border-bottom: solid thin #dadada;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
}

.column-selection-entry-group {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 1.5rem;
}

.tech-transfer-column-selection-tooltip-body {
  width: 600px;
}

.column-selection-tooltip-body {
  width: 365px;
}

.tech-transfer-filter-tooltip-body {
  width: 300px;
}

.column-selection-checkbox {
  text-align: center;
}

.tech-transfer-change-criteria-popup {
  max-width: 600px;
}

.tech-transfer-change-criteria-popup-body {
  height: calc(100vh - 200px);
  overflow: auto;
}

.tech-transfer-change-criteria-popup-tooltip {
  width: 235px;
}

.tech-transfer-filter-horizontal-separator {
  border-bottom: solid thin $brand-light-grey;
  margin-right: 10px;
  margin-top: 5px;
}

.dot,
.red-dot,
.grey-dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
}

.red-dot {
  background-color: red;
}

.grey-dot {
  background-color: $brand-medium-grey;
}

.two-lines-header, .column-header {
  font-weight: 600 !important;
}

.two-lines-header > div > div {
  bottom: 12px;
}

.filter-entry {
  background: white;
  padding: 5px;
  border: solid thin;
  border-radius: 3px;
}

.sort-entry,
.filter-entry-header {
  background: $brand-highlight-color;
  border-right: solid thin $brand-medium-grey;
  padding: 5px;
  border-radius: 2px 0 0 2px !important;
}

.filter-entry-header {
  background: #ffc49f;
}

.column-operation-close {
  color: $brand-medium-grey;
  cursor: pointer;
}

.filter-selected-entry {
  border-top: $brand-highlight-color solid 5px !important;
}

.filter-sort-combined-entry {
  border-image-slice: 1 !important;
  border-image-source: linear-gradient(to right, #2dc9a4 50%, #ffc49f 50%) !important;
}

.tech-transfer-copy-to-clipboard {
  color: $brand-medium-grey !important;
  font-size: 18px;
}

.tech-transfer-copy-to-clipboard:hover {
  color: $brand-dark-grey !important;
}

.tech-transfer-copy-to-clipboard-check {
  color: #acba37;
  font-size: 15px;
}


.configurable-tables-expanded-header:first-child {
  border-left: solid 5px #087d88 !important;
}

.configurable-tables-filtered-records-title {
  font-size: 18px;
  font-weight: bold;
}
