/* This SCSS file is responsible for all of the CSS on rmp pages.
 */
@import 'brand_colors';
@import 'globals';

.rmp-criticality-color {
  width: 20px;
  height: 20px;
}

.rmp-criticality-color-blue {
  background: blue;
}

.rmp-criticality-color-grey {
  background: grey;
}

.rmp-criticality-color-green {
  background: green;
}

.rmp-criticality-color-yellow {
  background: yellow;
}

.rmp-criticality-color-orange {
  background: orange;
}

.rmp-criticality-color-red {
  background: red;
}

.rmp-attachments-section-header {
  display: inline-block;
}

.rmp-riskSchema-section-header,
.include-risk-links-section-header {
  display: inline;
  width: 100%;
}

.rmp-attachments-attribute {
  padding-right: 0;
  padding-left: 0;
}

.rmp-copy-btn-flex {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.rmp-tab-content-configured-by-type {
  border-left: 1px solid $brand-mild-grey;
  border-right: 1px solid $brand-mild-grey;
  border-bottom: 1px solid $brand-mild-grey;
}

.rmp-requirement-risk-tab-options {
  padding-top: 10px;
}

.rmp-requirement-risk-tab {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 10px;
}

#rmpListPage .table-manage {
  min-width: 150px;
}

.rmp-tab-nav-bar-icon {
  margin-left: 5px;
}

.conversion-matrix-widget-details {
  padding-top: 10px;
}

.rmp-risk-table-section-row {
  margin-top: 10px;
}

.rmp-risk-table-options-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.71em;
}

#RMPRiskTableContainer {
  width: 100%;

  table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-collapse: collapse !important;
  }

  #RMPRiskTableContainerColumnHeader,
  #RMPRiskTableContainerRowHeader {
    border: solid 1px $brand-mild-grey;
    text-align: center;
    padding: 10px;
  }

  #RMPRiskTableContainerRowHeader {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .rmp-risk-table-container-row-header-panel {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    border: solid 1px $brand-mild-grey;
    overflow-x: hidden;
  }

  .rmp-risk-table-matrix-container {
    overflow-x: auto;
  }
}

.rmp-risk-table-flex-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rmp-risk-table-container {
  margin-bottom: 10px;
}

.rmp-risk-table-flex-cell-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.rmp-risk-table-show-label {
  margin-right: 10px;
}

.rmp-risk-table-row-header-cell {
  font-weight: bold;
}

.rmp-risk-tables-radio-group-separator {
  height: 18px;
  margin-left: 5px;
  margin-right: 10px;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  padding: 0;
}

.rmp-hidden {
  display: none;
}
