
.no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/** FontAwesome override **/
.fa-align-middle {
  vertical-align: baseline !important;
}

/** Color utilities */
.gray-color {
  color: #859399;
}

